<template>
  <v-container fluid class="fill-height" dark >

    <v-row>
      <v-col cols="10" offset="1" sm="8" offset-sm="2" md="6" offset-md="3" lg="4" offset-lg="4" class="pa-0">
        <v-row style="background: rgba(0,0,0,.2); border-radius: 16px" class="pa-5">
          <v-col cols="12" class="d-flex justify-start" >
            <h2 class="display-1 white--text d-inline-flex align-center justify-start fw-1 no-select" >RIEPILOGO ORDINE</h2>
          </v-col>
          <v-col cols="12">
            <v-divider dark/>
          </v-col>

          <v-col cols="12" class="d-flex justify-start" v-for="item in cart.items" :key="item">
            <v-icon color="white">mdi-chevron-right</v-icon><p class="title white--text d-inline-flex no-select mb-0" >{{getName(item)}}: {{getPrice(item).toFixed(2)}}€<span v-if="profile.user_type === 'commercial' || isHomeKit(item)"> iva inclusa</span></p>
          </v-col>


          <v-col v-if="needsCard && !homeKitAsProduct" cols="12" class="d-flex justify-start" >
            <v-icon color="white">mdi-chevron-right</v-icon><p class="title white--text d-inline-flex no-select mb-0" >Tesseramento MSP Italia (obbligatorio): {{coniCardPrice}}€</p>
          </v-col>
          <v-col v-if="needsExpedition" cols="12" class="d-flex justify-start" >
            <v-radio-group dark v-model="cart.expedition" @change="saveCart()">
              <v-radio class="white--text" :label="'Spedizione fra '+settings.home_kit_expedition_days+' giorni lavorativi*: '+settings.home_kit_expedition_price.toFixed(2)+'€'" :value="true"></v-radio>
              <v-radio class="white--text" :label="'Ritiro in sede presso Piazzale Kennedy n. 1/B – 06012, Città di Castello (PG) fra '+settings.home_kit_expedition_days+' giorni lavorativi: '" :value="false"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="12" v-if="needsExpedition">
            <span class="caption white--text no-select">*il giorno di arrivo del pacco potrebbe variare a seconda della tipologia di spedizione e della disponibilità del corriere</span>
          </v-col>
          <v-col cols="12">
            <v-divider dark/>
          </v-col>

          <v-col cols="12" class="d-flex justify-start">
            <p class="title white--text d-inline-flex no-select " >Totale: {{total.toFixed(2)}}€</p>
          </v-col>
          <v-col cols="12" class="d-flex justify-center pa-4" style="border-radius: 16px" >
            <v-row>
              <v-col cols="12" v-if="needsCard || cart.expedition">
                <h4 class="title white--text d-flex no-select full-width fw-1 text-uppercase align-center">Dati per la spedizione <v-btn class="d-flex ml-3 justify-self-end underline" rounded text small color="accent" @click="copyAddress()">copia dal tuo profilo</v-btn></h4>
                <p class="mt-2 subtitle-1 white--text no-select fw-3" v-if="needsCard && !cart.expedition"><b>Nota</b>: Anche se hai scelto il ritiro in sede dell'Home Kit, i dati di spedizione ci servono per poterti recapitare la tessera MSP Italia</p>
              </v-col>
              <v-col cols="12" v-if="needsCard || cart.expedition">
                <v-text-field
                    dark
                    label="Indirizzo*"
                    rounded filled
                    v-model="address.name"
                    :error="addressError"
                    @input="addressError = false"
                    :error-messages="requiredErrorMessages(addressError)"
                    hint="Via\Piazza ..."
                ></v-text-field>
              </v-col>
              <v-col cols="8" v-if="needsCard || cart.expedition" >
                <v-text-field
                    dark
                    label="Città*"
                    rounded filled
                    v-model="address.city"
                    @input="cityError = false"
                    :error="cityError"
                    :error-messages="requiredErrorMessages(cityError)"
                ></v-text-field>
              </v-col>

              <v-col cols="4" v-if="needsCard || cart.expedition">
                <v-text-field
                    dark
                    label="Prov.*"
                    rounded filled
                    v-model="address.province"
                    @input="provError = false"
                    :error="provError"
                    :error-messages="requiredErrorMessages(provError)"
                    hint="es. PG"
                ></v-text-field>
              </v-col>
              <v-col cols="12" v-if="needsCard || cart.expedition">
                <v-text-field
                    dark
                    label="CAP*"
                    rounded filled
                    v-model="address.zip_code"
                    :error="capError"
                    @input="capError = false"
                    :error-messages="requiredErrorMessages(capError)"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="d-flex justify-center" v-if="step !== 'paypal'">
                <v-btn v-if="needsCard || cart.expedition" class="flex-shrink-1 flex-grow-0" color="accent" dark rounded @click="validateAddress()">Spedisci qui</v-btn>
                <v-btn v-else class="flex-shrink-1 flex-grow-0" color="accent" dark rounded @click="paypalStep()">Metodi di pagamento</v-btn>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" v-if="step === 'paypal'">
            <h4 class="title white--text d-flex no-select full-width fw-1 text-uppercase align-center">SELEZIONA IL METODO DI PAGAMENTO</h4>
          </v-col>
          <v-col cols="12" v-if="step === 'paypal'" class="pa-4 white" style="border-radius: 16px" id="paypal-body" >
            <div id="paypal-button-container" class="paypal-button-container"></div>

            <!-- Containers for Card Fields hosted by PayPal -->

            <div id="card-form" class="card_container">

              <h3 class="title accent--text px-2">Inserisci i dati della tua carta</h3>

              <div id="card-name-field-container"></div>

              <div id="card-number-field-container"></div>

              <div id="card-expiry-field-container"></div>

              <div id="card-cvv-field-container"></div>

              <v-btn @click="payWithCard()" rounded color="accent" class="full-width title" x-large elevation="0">Paga ora con la carta</v-btn>

            </div>

            <p id="result-message"></p>
          </v-col>
        </v-row>
      </v-col>

    </v-row>
  </v-container>
</template>
<script>

import {mapActions, mapGetters} from "vuex";
import config from "../config";
import moment from 'moment'
import {loadScript} from "@paypal/paypal-js";
export default {
  name: "Checkout",
  data() {
    return {
      step : 'address',
      addressError : false,
      cityError : false,
      provError : false,
      capError : false,
      paypal : null,
      cardField : null
    };
  },
  computed: {
    ...mapGetters(['token', 'profile','bgImage','products', 'purchases','settings','categories','genre','difficulties','userSeats']),
    coniCardPrice(){
      return this.settings.coni_card_price;
    },
    numSeats() {
      return this.userSeats.num_seats - this.userSeats.num_booked;
    },
    address() {
      return this.cart.address;
    },
    loggedIn() {
      return this.profile != null
    },
    loading : {
      set(newVal) {  this.$store.state.loading = newVal },
      get() { return this.$store.state.loading }
    },
    cart: {
      set(newVal) {  this.$store.state.cart = newVal },
      get() { return this.$store.state.cart }
    },
    notLoggedDialog: {
      set(newVal) {  this.$store.state.notLoggedDialog = newVal },
      get() { return this.$store.state.notLoggedDialog }
    },
    homeKitAsProduct() {
      let numKitProducts = 0;
      for(let i = 0; i < this.cart.items; i++) {
        const itemId = this.cart.items[i];
        const product = this.getProduct(itemId);
        if(product.sku === config.homeKitSkuF || product.sku === config.homeKitSkuM) {
          numKitProducts++
        }
      }

      return numKitProducts === this.cart.items.length;
    },
    total() {
      let total = 0;
      for(let i = 0; i < this.cart.items.length; i++) {
        total += this.getPrice(this.cart.items[i]);
      }

      if(this.needsCard && !this.noMspItems) {
        total += this.settings.coni_card_price;
      }

      if(this.cart.expedition) {
        total += this.settings.home_kit_expedition_price;
      }

      if(this.cart.sub_pause_days) {
        total += this.settings.extra_day_of_sub_pause_price * this.cart.sub_pause_days;
      }

      return total;
    },
    totalNoExpedition() {
      let total = 0;
      for(let i = 0; i < this.cart.items.length; i++) {
        total += this.getPrice(this.cart.items[i]);
      }

      if(this.needsCard) {
        total += this.settings.coni_card_price;
      }
      if(this.cart.sub_pause_days) {
        total += this.settings.extra_day_of_sub_pause_price * this.cart.sub_pause_days;
      }

      return total;
    },
    noMspItems() {
      if(!this.cart) return true;
      return !this.cart.items || !this.cart.items.length;
    },
    needsCard() {
      return this.profile && this.profile.user_type === 'card' && !this.profile.has_valid_card;
    },
    needsExpedition() {
      for(let i = 0; i < this.cart.items.length; i++) {
        const p = this.getProduct(this.cart.items[i]);
        if(p.sku === config.seatSku) continue
        if(p && (p.sku === config.homeKitSkuF || p.sku === config.homeKitSkuM || (p.expedition && this.profile && !this.profile.home_kit))) {
          return true;
        }
      }
      return false
    },
  },
  methods: {
    ...mapActions(['logout', 'load', 'sendError','refreshCollection','requestConfirm','onScroll','refreshCollectionPromise',
      'removeFromCart','saveUserCart','saveUserCartPromise','loadProfile','newCart','loadPurchases','captureOrder','apiCreateOrder']),
    requiredErrorMessages(err) {
      if (err) return ["Riempi questo campo!"];
      return [];
    },
    formattedDate(date) {
      return moment(date).format("DD/MM/YYYY")
    },
    saveCart() {
      setTimeout(()=>{
        this.saveUserCart();
      }, 500);
    },
    copyAddress() {
      this.address.name = this.profile.address.name;
      this.address.city = this.profile.address.city;
      this.address.province = this.profile.address.province;
      this.address.zip_code = this.profile.address.zip_code;
    },
    validateAddress() {
      this.addressError = !this.address.name;
      this.cityError = !this.address.city;
      this.provError = !this.address.province;
      this.capError = !this.address.zip_code;

      if(this.addressError || this.cityError || this.provError || this.capError) {
        return;
      }
      this.paypalStep();
    },

    paypalStep() {
      this.step = 'paypal'
      setTimeout(this.buildPaypalCheckout, 500);
    },
    coverUrl(id) {
      const p = this.getProduct(id);
      if(p) {
        return p.kit.cover.url
      }
      return '';
    },
    getName(id) {
      const p = this.getProduct(id);
      if(p) {
        return `${p.kit.title.it} - ${p.title.it}`;
      }
      return 0;
    },
    isHomeKit(id){
      const p = this.getProduct(id);
      return p.sku === config.homeKitSkuF || p.sku === config.homeKitSkuM;
    },
    isPurchased(product) {
      let filter = this.purchases.filter((item) => item.product._id === product._id)
      return filter.length > 0;
    },
    getPrice(id) {
      const p = this.getProduct(id);
      if(p) {
        let price = p.price;


        if(p.second_price && p.second_price > 0 && this.isPurchased(p)) {
          price = p.second_price;
        }
        if(p.is_seats_product || (!this.isHomeKit(id) && this.profile && this.profile.user_type !== 'commercial'))
          return price;
        const iva = price / 100 * config.iva;
        return (price + iva);
      }
      return 0;
    },
    getKit(id) {
      for(let i = 0; i < this.kits.length; i++) {
        if(this.kits[i]._id === id) return this.kits[i];
      }
      return null
    },
    getProduct(id) {
      for(let i = 0; i < this.products.length; i++) {
        console.log(this.products[i], id)
        if(this.products[i]._id === id) return this.products[i];
      }
      return null
    },
    buildPurchaseItem( item ) {

      let price = item.price;
      if(item.second_price && item.second_price > 0 && this.isPurchased(item)) {
        price = item.second_price;
      }
      const pi = {
        name: item.title.it,
        sku: item.sku,
        unit_amount: {
          currency_code: "EUR",
          value: price.toFixed(2),
        },
        quantity: "1"
      }

      if(item.sku === config.seatSku) {
        pi.quantity = `${this.cart.num_seats}`
      }

      if(this.profile.user_type === 'commercial' && !item.is_seats_product) {
        const iva = price / 100 * config.iva;
        pi.tax = {
          currency_code: "EUR",
          value: iva.toFixed(2),
        }
      }
      return pi;
    },
    async paypalCreateOrderCallback(data, actions) {
      if(config.debug) console.log(data, actions)
      this.loading = true;
      try {
        const orderId = await this.apiCreateOrder({cartId : this.cart._id})
        this.loading = false
        console.log("orderId", orderId)
        return orderId
      } catch (err) {
        this.loading = false
        throw new Error(err.message)
      }
    },
    paypalApproveCallback(data, actions) {
      if(config.debug) console.log(data, actions)
      this.loading = true;

      this.captureOrder({ order_id: data.orderID, cart: this.cart }).then((reply)=>{
        this.loadPurchases().then(() => {
          this.newCart();
          this.loading = false
          this.$router.replace("/paid");
        });
      }).catch((err)=> {
        this.loading = false
        this.$store.commit('paymentError', { err })
        this.$router.replace("/error");
      })

      /*
      // This function captures the funds from the transaction.
      return actions.order.capture().then((details) => {
        console.log(details)
        // This function shows a transaction success message to your buyer.
        if(details.status === 'COMPLETED') {
          this.cart.payment_method = 'online'
          this.cart.payment_uid = details.id
          if(this.cart.expedition) {
            this.cart.status = 'paid';
          } else {
            this.cart.status = 'closed'
          }


          if(!this.cart.home_kit && !this.cart.card) {
            this.cart.evaded = true
          }

          this.saveUserCartPromise().then(()=>{
            this.loadPurchases().then(() => {
              this.newCart();
              this.loading = false
              this.$router.replace("/paid");
            });

          }).catch((err)=>{
            this.sendError({message: err})
            this.loading = false
          });
          return
        }
        this.loading = false
      }).catch((err) => {
        this.sendError({message: err})
        this.loading = false
      });
       */
    },
    payWithCard() {
      if(!this.cardField) return
      this.cardField
          .submit({
            // From your billing address fields
            billingAddress: {
              addressLine1: this.address.name,
              addressLine2: '',
              adminArea1: this.address.city,
              adminArea2: this.address.province,
              countryCode: 'IT',
              postalCode: this.address.zip_code,
            },
          })
          .then(() => {
            // submit successful
          });
    },
    buildPaypalCheckout(){

      this.paypal.Buttons({
        style : {
          shape : 'pill'
        },
        createOrder: async (data, actions) => {
          return this.paypalCreateOrderCallback(data, actions);
        },
        onApprove: async (data, actions)  =>  {
          return this.paypalApproveCallback(data, actions);
        }
      }).render('#paypal-body');

      console.log(this.paypal)

      // Render each field after checking for eligibility
      const cardField = this.paypal.CardFields({
        createOrder: async (data, actions) => {
          return this.paypalCreateOrderCallback(data, actions);
        },
        onApprove: (data, actions)  =>  {
          this.paypalApproveCallback(data, actions);
        },
        onError : (error) => {
          console.error(error);
          // Handle the error object
          this.loading = false
          this.$store.commit('paymentError', { error })
          this.$router.replace("/error");


        },
        style: {
          shape : 'pill',
          input: {
            "font-size": "16px",
            "font-family": "Roboto, sans-serif",
            color: "#ccc",
          },
          ".invalid": { color: "#d43a2d" },
        },
      });
      this.cardField = cardField;
      if (cardField.isEligible()) {
        const nameField = cardField.NameField({
          style: { input: { color: "#111" }, ".invalid": { color: "#d43a2d" } },
        });
        nameField.render("#card-name-field-container");
        const numberField = cardField.NumberField({
          style: { input: { color: "#111" } },
        });
        numberField.render("#card-number-field-container");
        const cvvField = cardField.CVVField({
          style: { input: { color: "#111" } },
        });
        cvvField.render("#card-cvv-field-container");
        const expiryField = cardField.ExpiryField({
          style: { input: { color: "#111" } },
        });
        expiryField.render("#card-expiry-field-container");
      }
    }
  },
  async mounted() {
    this.$store.commit('paymentError', { err: null })
    this.loading = true;
    await this.loadProfile()
    if(!this.profile) {
      this.$router.go(-1);
      return
    }

    const res = await Promise.all([
      this.refreshCollectionPromise({collection: 'products'}),
      loadScript({ clientId: config.paypalClientId, currency: "EUR",components:['buttons','card-fields','hosted-fields']  })
    ])

    this.paypal = res[1]

    this.loading = false;
    if(!this.needsCard && !this.needsExpedition ) {
      this.paypalStep();
    }



  }
}
</script>

<style scoped>

</style>