const LEVEL = "preprod";
/**
 * ATTENZIONE!! RICORDATI DELLO SCRIPT PAYPAL IN INDEX.HTML
 */
const commons = {
    version : 'v2.1.23',
    homeKitSkuM : 'HOMEKITM',
    homeKitSkuF : 'HOMEKITF',
    seatSku : 'TOPGYMSEAT',
    iva : 22,
    hideFilterPage : true,
    homeAlternate : true,
    paypalClientId : 'AW7ehdqGtsnfter6J5ne24Pr8vRE8rlk2gqa5EIMs9qLc5mJ9Ig0VjLFM2yoEZdBq4CMSd1xpqSI3P5m'
};

const dev = {
    debug : true,
    develomentPage : false,
    sandbox: true,
    //googleMapApiKey : 'AIzaSyC8hkqKR6ugzF1yUmy5d7vB49pTRhs9UTg',
    server : 'http://localhost:4242',
    paypalClientId : 'AW7ehdqGtsnfter6J5ne24Pr8vRE8rlk2gqa5EIMs9qLc5mJ9Ig0VjLFM2yoEZdBq4CMSd1xpqSI3P5m'
};
const preprod = {
    debug : true,
    develomentPage : false,
    sandbox: true,
    //googleMapApiKey : 'AIzaSyC8hkqKR6ugzF1yUmy5d7vB49pTRhs9UTg',
    server : 'https://devapi.topupgym.pro',
    paypalClientId : 'AW7ehdqGtsnfter6J5ne24Pr8vRE8rlk2gqa5EIMs9qLc5mJ9Ig0VjLFM2yoEZdBq4CMSd1xpqSI3P5m'
};
const prod = {
    debug : false,
    develomentPage : false,
    sandbox: false,
    //googleMapApiKey : 'AIzaSyC8hkqKR6ugzF1yUmy5d7vB49pTRhs9UTg',
    server : 'https://api.topupgym.pro',
    paypalClientId : 'AQ_JJ3FNStA8UfTzOoXZazgOtZrvc1QXwWIDpY0Nj5iy4xsYjOtUUGGmA_GVfUPPHpDbc7LQ3zQ5lHtG'
};

let config;

switch (LEVEL) {
    case "prod":
        config = Object.assign(commons, prod);
        break;
    case "preprod":
        config = Object.assign(commons, preprod);
        break;
    case "dev":
    default:
        config = Object.assign(commons, dev);
        break;
}

export default config;