import Vue from 'vue'
import Vuex from 'vuex'
import api from '../api'
import sf01 from '../assets/sf01.jpg'
import sf02 from '../assets/sf02.jpg'
import sf03 from '../assets/sf03.jpg'
import sf04 from '../assets/sf04.jpg'
import sf05 from '../assets/sf05.jpg'
import sf06 from '../assets/sf06.jpg'
import sf07 from '../assets/sf07.jpg'
import config from "../config";
import utils from "../utils";
import moment from 'moment'
import {DateTime} from "luxon";
Vue.use(Vuex)

const getDefaultSettings = ()=> {
  if(localStorage.settings) {
    try {
      return  JSON.parse(localStorage.settings);
    } catch (e) {
      console.log("settings in localstorage parse err", e)
    }
  }
  return {
    "coni_card_price": 15,
    "home_kit_price": 60,
    "home_kit_expedition_price": 12,
    "home_kit_expedition_days": 7,
    "purchase_expiration_days_after_first_use": 45,
    "last_update": {
      "$date": "2024-09-05T07:02:20.340Z"
    },
    "open_days": {
      "lun": true,
      "mar": true,
      "mer": true,
      "gio": true,
      "ven": true,
      "sab": true,
      "dom": false
    },
    "user_bookings_per_week": {
      "$numberLong": "7"
    },
    "user_bookings_per_day": {
      "$numberLong": "1"
    },
    "bookings_per_hour_exp": 12,
    "bookings_per_hour_corsi": 8,
    "bookings_per_hour_macchine": 25,
    "bookings_per_hour_vacu": 1,
    "bookings_per_hour_gym": 4,
    "cancel_within_minutes": 120,
    "booking_within_minutes": 15,
    "booking_within_minutes_exp": 0,
    "booking_within_minutes_corsi": 0,
    "booking_within_minutes_macchine": 0,
    "booking_within_minutes_vacu": 0,
    "booking_within_minutes_gym": 0,
    "holidays": {
      "natale": true,
      "santo_stefano": true,
      "capodanno": true,
      "epifania": false,
      "pasquetta": true,
      "liberazione": true,
      "lavoro": true,
      "repubblica": true,
      "ferragosto": true,
      "santi": true,
      "immacolata": true
    },
    "day_of_sport_year_start": 1,
    "month_of_sport_year_start": 9,
    "max_days_of_subscription_pause": 10,
    "extra_day_of_sub_pause_price": 3,
    "banner_duration": 5
  }
}

const state = {
  $router : null,
  userInteracted : false,
  orientation : 'portraits',
  token : localStorage.token || null,
  currentBg : 0,
  bgs : [
    sf01,
    sf02,
    sf03,
    sf04,
    sf05,
    sf06,
    sf07,
  ],
  initialized : false,
  loading : false,
  loadingMessage : '',
  notLoggedDialog : false,
  profile : null,
  userSeats : [],
  bookings : [],
  alertDialog : false,
  alertTitle : 'Attenzione!',
  alertMessage : '',
  contractDialog : false,
  privacyDialog : false,
  successDialog : false,
  warningDialog : false,
  errorDialog : false,
  infoDialog : false,
  dialogMessage : '',
  confirmDialog : false,
  confirmDialogTitle: 'Richiesta conferma',
  confirmDialogMessage : 'Sei sicuro?',
  confirmDialogCallback : null,
  media : [],
  difficulties : [],
  categories : [],
  genre : [],
  exercises : [],
  users : [],
  kits : [],
  cms : [],
  products : [],
  purchases : [],
  bookingprefs : [],
  subcategories : [],
  banners : [],
  gallery : [],
  medicalhours : [],
  medicalbooking : [],
  paymentError : null,
  settings : getDefaultSettings(),
  onAlertCallback : () => {},
  cart : {
    user_id : null,
    items : [],
    expedition : false,
    sub_pause_days : 0,
    payment_method : 'online',
    payment_uid : '',
    status : 'draft',
    address: {
      name: "",
      city: "",
      province: "",
      zip_code: ""
    },
    block_edit : false,
    home_kit : false,
    card : false,
    evaded : false
  },
  offsetTop : 0,
};


const getters = {
  isAndroid : ( state ) => navigator.userAgent.match(/Android/i) !== null,
  appBarStyle : ( state ) => {

    if(state.$router && (state.$router.currentRoute.name === 'lesson' || state.$router.currentRoute.name === 'work')) {
      return `background: rgba(0,0,0,.4); background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,.4) 20%, rgba(0,0,0,.6) 50%, rgba(0,0,0,.8) 100%);`
    }

    let alpha = 0.3 * Math.min(state.offsetTop, 100) / 100;
    return 'background: rgba(0,0,0,'+alpha+');';
  },
  validUserSeats : ( state ) => {
    const today = DateTime.now().endOf('day')
    const filter = state.userSeats.filter(s => {
      if(s.expiration) {
        let exp = DateTime.fromISO(s.expiration)
        if(exp < today) {
          return false;
        }
      }
      return !(s.consumable && s.available <= s.used);

    })
    return filter
  },
  settings : ( state ) => state.settings,
  bookingprefs : ( state ) => state.bookingprefs,
  paymentError : ( state ) => state.paymentError,
  userInteracted : ( state ) => state.userInteracted,
  initialized : ( state ) => state.initialized,
  orientation  : (state) => state.orientation,
  userId  : (state) => state.profile._id,
  token  : (state) => state.token,
  bgImage  : (state) => state.bgs[state.currentBg],
  profile  : (state) => state.profile,
  userSeats  : (state) => state.userSeats,
  bookings  : (state) => state.bookings,
  confirmDialogMessage  : (state) => state.confirmDialogMessage,
  is_editor  : (state) => state.profile && state.profile.role === 'editor',
  is_admin  : (state) => state.profile && state.profile.role === 'admin',
  is_root  : (state) => state.profile && state.profile.role === 'root',
  media  : (state) => state.media,
  difficulties  : (state) => state.difficulties,
  categories  : (state) => state.categories,
  genre  : (state) => state.genre,
  exercises  : (state) => state.exercises,
  purchases  : (state) => state.purchases,
  kits  : (state) => state.kits,
  cms  : (state) => state.cms,
  products  : (state) => state.products,
  subcategories  : (state) => state.subcategories,
  banners  : (state) => state.banners,
  gallery  : (state) => state.gallery,
  medicalhours  : (state) => state.medicalhours,
  medicalbooking  : (state) => state.medicalbooking,
  ongoingLessons : (state) => state.profile.ongoing_lessons || [],
  doneLessons : (state) => state.profile.done_lessons || [],
  usablePurchases() {
    return state.purchases.filter((item) => {
      return !item.consumed && !utils.isExpired(item.expiration)
    });
  },
  ongoingPurchases(state) {
    return state.purchases.filter((item) => {
      return ((item.ongoing_lessons && item.ongoing_lessons.length) || (item.done_lessons && item.done_lessons.length)) && !item.consumed && !utils.isExpired(item.expiration)
    });
  },
  alertTitle  : (state) => state.alertTitle,
  alertMessage  : (state) => state.alertMessage,
  onAlertCallback  : (state) => state.onAlertCallback,
  cart  : (state) => state.cart,
  numItemsInCart  : (state) => {
    return state.cart ? state.cart.items.length || 0 : 0
  },
};

const mutations = {
  $router : (state, { router }) => {
    state.$router = router;
  },
  notFound: (state) => {
    state.$router.replace('/notfound');
  },
  logout : (state) => {
    state.token = null;
    state.profile = null;
    localStorage.removeItem('token');
    if(state.$router.currentRoute.name !== 'home') {
      state.loading = true;
      state.$router.replace('/')
      setTimeout(()=>{
        state.loading = false;
      }, 500);
    }

  },
  token : (state, { token }) => {
    state.token = token;
    if(token)
      localStorage.setItem("token" , token);
    else
      localStorage.removeItem("token");
  },
  profile : (state, { profile }) => {
    if(config.debug) console.log(profile);
    state.profile = profile;
    state.cart.user_id = profile._id;
  },
  userSeats : (state, { data }) => {
    state.userSeats = data;
  },
  bookings : (state, { data }) => {
    state.bookings = data;
  },
  alert : (state, { open, title, message }) => {
    state.alertDialog = open;
    state.alertTitle = title || '';
    state.alertMessage = message;
  },
  message : (state, { type, message }) => {
    switch (type) {
      case 'success':
        state.successDialog = true;
        state.dialogMessage = message;
        break;
      case 'warning':
        state.warningDialog = true;
        state.dialogMessage = message;
        break;
      case 'error':
        state.errorDialog = true;
        state.dialogMessage = message;
        break;
      case 'info':
      default:
        state.infoDialog = true;
        state.dialogMessage = message;
        break;
    }
  },
  confirm : (state, { title, message, callback }) => {
    state.confirmDialogTitle = title || 'Richiesta conferma';
    state.confirmDialogMessage = message || 'Sei sicuro?';
    state.confirmDialogCallback = callback || null;
    state.confirmDialog = true;
  },
  onAlertCallback : (state, { fn }) => {
    state.onAlertCallback = fn;
  },
  media  : (state, { data }) => {
    state.media = data;
  },
  categories  : (state, { data }) => {
    state.categories = data;
  },
  genre  : (state, { data }) => {
    state.genre = data;
  },
  difficulties  : (state, { data }) => {
    state.difficulties = data;
  },
  exercises  : (state, { data }) => {
    state.exercises = data;
  },
  kits  : (state, { data }) => {
    state.kits = data;
  },
  cms  : (state, { data }) => {
    state.cms = data;
  },
  products  : (state, { data }) => {
    state.products = data;
  },
  purchases  : (state, { data }) => {
    state.purchases = data;
  },
  settings  : (state, { data }) => {
    localStorage.settings = JSON.stringify(data)
    state.settings = data;
  },
  banners  : (state, { data }) => {
    state.banners = data;
  },
  gallery  : (state, { data }) => {
    state.gallery = data;
  },
  medicalhours  : (state, { data }) => {
    state.medicalhours = data;
  },
  medicalbooking  : (state, { data }) => {
    state.medicalbooking = data;
  },
  bookingprefs  : (state, { data }) => {
    state.bookingprefs = data;
  },
  subcategories  : (state, { data }) => {
    state.subcategories = data;
  },
  paymentError  : (state, { err }) => {
    state.paymentError = err;
  },
  nextBg: (state) => {
    state.currentBg = state.currentBg >= 6 ? 0 : state.currentBg+1;
  },
  cart : (state, { data }) => {
    if(state.cart.items && state.cart.items.length) {
      let toAdd = [];
      for(let j = 0; j < state.cart.items.length; j++) {
        const locItem = state.cart.items[j];
        let found = false
        for(let i = 0; i < data.items.length; i++) {
          const srvItem = data.items[i];
          if(locItem === srvItem) {
            found = true
          }
        }
        if(!found) {
          toAdd.push(locItem);
        }
      }
      if(toAdd.length) data.items.push(...toAdd);
    }
    state.cart = data;
  },
  pauseDaysInCart: (state, { days }) => {
    state.cart.sub_pause_days = days
  },
  addToCart: (state, { product }) => {
    if(!product.is_seats_product) {
      for(let i = 0; i < state.purchases.length; i++) {
        const purchase = state.purchases[i];
        if(purchase.product._id === product._id && !purchase.consumed && !moment(purchase.expiration).isBefore(moment()) && purchase.product_sku !== config.homeKitSkuF && purchase.product_sku !== config.homeKitSkuM && purchase.product_sku !== config.seatSku) {
          state.errorDialog = true;
          state.dialogMessage = "Sei già in possesso di questo elemento ed è ancora disponibile!";
          return;
        }
      }
    }

    for(let i = 0; i < state.cart.items.length; i++){
      if(state.cart.items[i] === product._id) {
        return
      }
    }
    if(product.is_seats_product) {
      state.cart.items = state.cart.items.filter(id => {
        let p = utils.getObjectInArray(state.products, '_id', id)
        return !!p && p.kit._id !== product.kit._id;
      });
    }
    state.cart.items.push(product._id);
  },
  removeFromCart: (state, { id }) => {
    for(let i = 0; i < state.cart.items.length; i++) {
      if(state.cart.items[i] === id) {
        state.cart.items.splice(i, 1);
        break;
      }
    }
  },
  voidCart: (state) => {
    state.cart.sub_pause_days = 0;
    state.cart.items = [];
  },
  newCart: (state) => {
    state.cart = {
      user_id : null,
      items : [],
      expedition : false,
      sub_pause_days : 0,
      payment_method : 'online',
      payment_uid : '',
      status : 'draft',
      address: {
        name: "",
        city: "",
        province: "",
        zip_code: ""
      },
      block_edit : false,
      home_kit : false,
      card : false,
      evaded : false,
    };
  },
  offsetTop: (state, { value }) => {
    state.offsetTop = value
  },
  ongoing : (state, { lesson_id, purchase_id}) => {
    state.purchases = state.purchases.map((item) => {
      if(item._id === purchase_id) {
        if(item.done_lessons && item.done_lessons.includes(lesson_id)) {
          return item;
        }
        if(!item.ongoing_lessons) {
          item.ongoing_lessons = [];
        }
        if(!item.ongoing_lessons.includes(lesson_id)) {
          item.ongoing_lessons.push(lesson_id)
        }
      }
      return item;
    })
  },
  done : (state, { lesson_id, purchase_id }) => {
    state.purchases = state.purchases.map((item) => {
      if(item._id === purchase_id) {
        if(item.ongoing_lessons && item.ongoing_lessons.includes(lesson_id)) {
          item.ongoing_lessons = item.ongoing_lessons.filter((id) => id !== lesson_id );
        }
        if(!item.done_lessons) {
          item.done_lessons = [];
        }
        if(!item.done_lessons.includes(lesson_id)) {
          item.done_lessons.push(lesson_id)
        }
      }
      return item;
    })

  }
};

const actions = {
  setOngoingWorkout: ({commit}, {purchase_id, lesson_id, schedule }) => {
    if(!purchase_id || !lesson_id || schedule === null) {
      localStorage.removeItem("resume")
    } else {
      localStorage.setItem("resume", [purchase_id, lesson_id, schedule].join('|'))
    }
  },
  onScroll : ({commit}, {offsetTop}) => {
    commit('offsetTop', { value : offsetTop });
  },
  addToCart : ({commit}, { product, count }) => {
    commit('addToCart', { product, count });
  },
  setPauseDaysToCart : ({commit}, { days }) => {
    commit('pauseDaysInCart', { days });
  },
  voidCart : ({commit}) => {
    commit('voidCart');
  },
  removeFromCart : ({commit},{id}) => {
    commit('removeFromCart', { id })
  },
  loadUserCart : ({commit}) => {
    return new Promise((resolve) => {
      api.restGet(state.token, 'profile/carts','current').then((reply)=>{
        if(reply.data) {
          commit('cart', { data : reply.data })
          resolve(reply.data)
          return
        }
        if(state.cart._id) {
          state.cart._id = null
          commit('cart', { data : state.cart })
        }
        resolve(null)
      }).catch((err)=>{
        if(config.debug) console.error(err);
        if(state.cart._id) {
          state.cart._id = null
          state.cart.items = []
          commit('cart', { data : state.cart })
        }
        resolve(null)
      })
    })
  },

  saveUserCart : ({commit}) => {
    if(!state.profile) return;
    if(!state.cart._id) {
      api.restInsert(state.token, 'carts', state.cart).then((reply)=>{
        if(reply.data) {
          commit('cart', { data : reply.data });
        }
      }).catch(console.warn)
      return
    }
    api.restUpdate(state.token, 'carts', state.cart).then((reply)=>{
      if(reply.data) {
        commit('cart', { data : reply.data })
      }
    }).catch(console.warn)
  },
  saveUserCartPromise : ({commit}) => {
    if(!state.profile) return Promise.reject("Non sei loggato!");
    return new Promise((resolve, reject) => {
      if(!state.cart._id) {
        api.restInsert(state.token, 'carts', state.cart).then((reply)=>{
          if(reply.data) {
            commit('cart', { data : reply.data });
            resolve(reply.data);
            return;
          }
          reject("Errore sconosciuto");
        }).catch((err)=>{
          if(config.debug) console.warn(err)
          reject(err);
        });
        return
      }
      api.restUpdate(state.token, 'carts', state.cart).then((reply)=>{
        if(reply.data) {
          commit('cart', { data : reply.data });
          resolve(reply.data);
          return;
        }
        reject("Errore sconosciuto");
      }).catch((err)=>{
        if(config.debug) console.warn(err)
        reject(err);
      });
    });
  },
  apiCreateOrder : ({commit}, {cartId}) => {
    return new Promise((resolve, reject) => {
      api.restGet(state.token, 'carts', `${cartId}/createorder?sandbox=${config.sandbox}`).then((reply)=>{
        if(reply.data) {
          console.log("on order created", reply.data)
          commit('cart', { data : reply.data.cart });
          resolve(reply.data.order_id );
          return;
        }
        reject("Errore sconosciuto");
      }).catch((err)=>{
        if(config.debug) console.warn(err)
        const message = err && err.message ? err.message : 'Errore sconosciuto';
        reject(message);
      });
    });
  },
  captureOrder : ({commit}, {order_id, cart}) => {
    if(!state.profile) return Promise.reject("Non sei loggato!");
    if(!order_id || !cart) return Promise.reject("Richiesta non valida");
    return new Promise((resolve, reject) => {
      api.restInsert(state.token, 'carts/'+cart._id + "/capture/" + order_id, cart).then((reply)=>{
        if(reply.data) {
          commit('cart', { data : reply.data });
          resolve(reply.data);
          return;
        }
        reject("Errore sconosciuto");
      }).catch((err)=>{
        if(config.debug) console.warn(err)
        const message = err && err.message ? err.message : 'Errore sconosciuto';
        reject(message);
      });
    });
  },
  newCart : ({commit}) => {
    commit('newCart');
  },
  router : ({commit}, { router }) => {
    commit('$router', { router });677923
  },
  notFound : ({commit}) => {
    commit('notFound');
  },
  nextBg : ({commit}) => {
    commit('nextBg');
  },
  login : ({commit}, { email, password}) => {
    return api.login( email, password );
  },
  register : ({commit}, { body }) => {
    return api.register( body );
  },
  profileUpdate : ({commit}, { token, body }) => {
    return api.profileUpdate( token, body );
  },
  confirmRegister({commit},{ token }) {
    return api.confirmRegister(token);
  },
  loadProfile({commit}) {
    return new Promise((resolve, reject) => {
      api.profile(state.token).then((reply) => {
        if(reply.success) {
          commit('profile', { profile : reply.data });
          resolve();
          return
        }
        commit('logout');
        reject();
      }).catch(()=>{
        commit('logout');
        reject();
      })
    })
  },
  loadSettings({commit}) {
    api.restGet(state.token, "settings", null).then((reply) => {
      if(reply.success) {
        commit('settings', { data : reply.data });
      }
    }).catch(console.error)
  },
  loadPurchases({commit}) {
    return new Promise((resolve, reject) => {
      let user = state.profile ? state.profile._id : ''
      api.restLoad( state.token, 'user/purchases', { user }, 0, 1, "date", "desc").then(( reply )=>{
        if(reply.data) {
          commit('purchases', { data : reply.data });
          resolve()
        }
      }).catch(reject)
    });
  },

  loadLessons({commit},{ product_id }) {
    return new Promise((resolve, reject) => {
      api.restLoad( state.token, `products/${product_id}/lessons`, {}, 0, 1, null, null).then(( reply )=>{
        if(reply.data) {
          resolve(reply.data)
          return
        }
        reject({ status : 404, error : "Lezioni non trovate"})
      }).catch(reject)
    });
  },
  loadLesson({commit},{ product_id, id }) {
    return new Promise((resolve, reject) => {
      api.restGet( state.token, `products/${product_id}/lessons`, id).then(( reply )=>{
        if(config.debug) console.log("lesson reply", reply)
        if(reply.data) {
          resolve(reply.data)
          return
        }
        reject({ status : 404, error : "Lezione non trovata"})
      }).catch(reject)
    });
  },
  logout : ({ commit }) => {

    commit('logout')
  },
  setToken : ({ commit }, { token }) => {
    commit('token', { token })
  },
  setProfile : ({ commit }, { profile }) => {
    commit('profile', { profile })
  },
  setOngoing : ({ commit }, { purchase_id, lesson_id }) => {
    commit('ongoing', { lesson_id, purchase_id })
    let id = purchase_id + "/ongoing/" + lesson_id;
    api.restGet(state.token, 'purchases', id).then(()=>{}).catch(console.log);
    //api.profileUpdate(state.token, state.profile).then(()=>{}).catch(console.log);
  },
  setDone : ({ commit }, { purchase_id, lesson_id }) => {
    commit('done', { lesson_id, purchase_id })
    let id = purchase_id + "/done/" + lesson_id;
    api.restGet(state.token, 'purchases', id).then(()=>{}).catch(console.log);
    //api.profileUpdate(state.token, state.profile).then(()=>{}).catch(console.log);
  },
  alert({commit}, { message }) {
    return new Promise((resolve) => {
      commit('onAlertCallback', { fn : () =>{
          commit('alert', { open: false, message : '' })
          resolve();
        }
      });
      commit('alert', { open: true, message })
    })
  },
  sendSuccess({ commit }, { message }) {
    commit('message', { type : 'success', message })
  },
  sendWarning({ commit }, { message }) {
    commit('message', { type : 'warning', message })
  },
  sendError({ commit }, { message }) {
    commit('message', { type : 'error', message })
  },
  sendInfo({ commit }, { message }) {
    commit('message', { type : 'info', message })
  },
  requestConfirm({commit}, { title, message, callback }) {
    commit('confirm', { title, message, callback })
  },
  load({commit}, { collection, filter }) {
    let { query, limit, page, sort, order } = filter;
    let noauth = filter.noauth || false;
    return api.restLoad( state.token, collection, query, limit, page, sort, order, noauth);
  },
  get({commit}, { collection, id }) {
    return api.restGet( state.token, collection, id);
  },
  insert({commit}, { collection, data }) {
    return api.restInsert( state.token, collection, data );
  },
  remove({commit}, { collection, id }) {
    return api.restDelete( state.token, collection, id );
  },
  update({commit}, { collection, data }) {
    return api.restUpdate( state.token, collection, data );
  },
  upload({commit}, { where, formData }) {
    return api.upload( state.token, where, formData);
  },
  resetPassword({commit}, { email }) {
    return api.post( 'reset', { email }, state.token);
  },
  refreshCollection({ commit }, { collection, sort, order }) {
    if(!sort) sort = "";
    if(!order) order = "asc";
    api.restLoad( state.token, collection, {}, 0, 1, sort, order).then(( reply )=>{
      if(reply.data) {
        commit(collection, { data : reply.data });
      }
    }).catch((err) => { console.log(err) })
  },
  refreshCollectionPromise({ commit }, { collection, sort, order }) {
    return new Promise((resolve)=>{
      if(!sort) sort = "";
      if(!order) order = "asc";
      api.restLoad( state.token, collection, {}, 0, 1, sort, order).then(( reply )=>{
        if(reply.data) {
          commit(collection, { data : reply.data });
        }
        resolve();
      }).catch((err) => { console.log(err); resolve(); })
    });
  },
  async loadSeats({ commit }) {
    try {
      let { data } = await api.restGet(state.token, "myseats")
      commit('userSeats', { data })
    } catch (e) {
      console.log(e)
    }
  },
  async loadBookings({ commit }, { query }) {
    try {
      let { data } = await api.restLoad(state.token, "booking", query, 0, 1, null, null, false)
      commit('bookings', { data })
    } catch (e) {
      console.log(e)
    }
  },
};

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules: {}
})
